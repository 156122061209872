.game-window {
	position: relative;
	height: auto;
	margin-top: auto;
	margin-bottom: auto;
	border: yellow;
}

.balloon {
	position: absolute;
	width: 45px;
	height: 65px;
	/* background-color: #ff4081; */
	border-radius: 50%;
}
.btn {
	text-shadow: 3px 3px 2px black;
	box-shadow: 5px 5px 5px black;
}
.game-over {
	position: absolute;
	height: auto;
}
