img{
    max-width: 100%;
    max-height: 80%;
}
.box {
    margin: .5em;
    width: 6em;
    height:6em;
    perspective: 1000px;
}
.btn-primary {text-shadow: 2px 2px black}


/* This container is needed to position the front and back side */
.flip-card-inner {
    
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flipped {
    transform: rotateY(180deg);
}


/* Position the front and back side */
.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    /* Safari */
    backface-visibility: hidden;
}


/* Style the front side (fallback if image is missing) */
.flip-card-front {
    background-color:rgb(177, 29, 29);
    color: black;
    border-style: solid;
    border-color: black;
}

/* Style the back side */
.flip-card-back {
    width: 100%;
    background-color: rgb(222, 244, 212);
    color: rgb(0, 0, 0);
    transform: rotateY(180deg);
    box-shadow: "0 0 50px #76ff03";
            width: 40;
            height: 40;
                  animation: "bounce 1s infinite"
}