.btn {
    text-shadow:3px 3px 2px black; 
    box-shadow: 5px 5px 5px black;
}
.dice {
    height: 150px;
    width: 150px;
    position: relative;
    transform-style: preserve-3d;
    transition: transform .96s;
}

.panel {
    border-radius: 10px;
    width: 150px;
    height: 150px;
    perspective: 400px;
    position: relative;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
}

.dot {
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin: 10px;
    background-color: Black;
}

.side {
    position: absolute;
    background-color: rgb(243, 220, 220);
    width: 150px;
    height: 150px;
    border-radius: 16px;
    line-height: 150px;
    border-color: rgb(72, 71, 71);
    border-width: 5px;
    border-style: ridge;

}

.one {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateX(-90deg) translateZ(74px);
    z-index: 1;
}

.two {
    display: flex;
    justify-content: space-between;
    transform: rotateY(180deg) translateZ(74px);
    z-index: 2;
}

.two .dot:nth-of-type(2) {
    align-self: flex-end;
}

.three {
    display: flex;
    justify-content: space-between;
    transform: rotateY(-90deg) translateZ(74px);
    z-index: 3;
}

.three .dot:nth-of-type(2) {
    align-self: center;
}

.three .dot:nth-of-type(3) {
    align-self: flex-end;
}

.four {
    z-index: 4;
    transform: rotateY(0deg) translateZ(74px);
}

.four,
.six {
    display: flex;
    justify-content: space-between;
}

.four .kolona,
.six .kolona {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.five {
    display: flex;
    justify-content: space-between;
    transform: rotateY(90deg) translateZ(74px);
    z-index: 5;
}

.five .kolona {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.five .kolona:nth-of-type(2) {
    justify-content: center;
}

.six {
    transform: rotateX(90deg) translateZ(74px);
    z-index: 6;
}

.diceBtn {
    padding: .5em;
    margin: .5em;
    font-size: large;
    width: 3.3em
}

/* CUSTOMIZE THE WIGGLE EFFECT LATER */
@keyframes wiggle {
    0% {
        transform: rotate(0deg);
    }

    80% {
        transform: rotate(0deg);
    }

    85% {
        transform: rotate(5deg);
    }

    95% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.wiggle {
    display: inline-block;
    animation: wiggle .5s;
}