code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: black;
  background-image: url(./images/pramod-tiwari-3UgbE1b43j8-unsplash.jpg);
  background-attachment: fixed;
  background-size: 120%;
  margin: 0;
  /* font-family: 'Lilita One', cursive; */
  font-family: 'Fredoka One', cursive;
  color: white;
}
.startBtn{
  /* height: 8em; */
  font-size:100%
  
}

.gameText{
    color:white;
    margin:.5em;
    text-shadow: 4px 4px 2px black; 
}
a:link {
    text-decoration: none;
}

.hidden {
  opacity: 0
}