.factory{
    border-style: groove;
    border-color: rgb(199, 199, 192);
    border-width: .3em;
    min-height: 400px;
    box-shadow: inset -2em 2em 2em black;
    max-width: 90%;
    background-image: url("/krzysztof-kowalik-djdoRnnKLyo-unsplash.jpg");
    background-size: cover;
    background-position: 50%;
}
.rnd-btn{
    margin:.3em;
    width: 80px
}
.dev_border{
    border-style:dotted;
    border-color: white;
    border-width: 2px;
}
.new_head{
    -webkit-transform: rotateX(-90deg) translateZ(-50px);
        transform: rotateX(-90deg) translateZ(-50px);
        -webkit-transition: -webkit-transform .3s;
            transition: transform .3s;
            /* Animate the transform properties */
        
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            /* <-NB */
            height: 100px;
}
.static {
    animation-name: static;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}

.static_swinging {
    animation-name: static_swinging;
    animation-duration: 3s;
    animation-iteration-count: infinite;   
}
.static_swinging_right {
    animation-name: static_swinging_right;
    animation-duration: 3.8s;
    animation-iteration-count: infinite;
    transform: scaleX(-1);
}
.drive {
    transform: scale(.7);
    animation-name: drive;
    animation-duration: 5s;
    ;
}
@keyframes drive {
    0% {transform: scale(.01);
        left: 300px;
        top: 200px
        
    }

    25% {
        transform: scale(.1);
            left: 100px;
            top: 200px
    }

    50% {
        transform: scale(.5);
            left: 25px;
            top: 200px
    }

   

    100% {
        transform: scale(.7);
        top:0;
        
    }
}

@keyframes static {
    0% {
        left: 20px ;
    }

    25% {
        left: 25px;
    }

    50% {
        left: 25px;
    }

    75% {
        left: 30px;
    }

    100% {
        left: 20px;
    }
}

@keyframes static_swinging {
    0% {
        transform: rotate(5deg);
    }

    25% {
        transform: rotate(6deg);
    }

    50% {
        transform: rotate(3deg);
    }

    75% {
        transform: rotate(2deg);
    }

    100% {
        transform: rotate(5deg);
    }
}
@keyframes static_swinging_right {
    0% {
        transform: scaleX(-1) rotate(5deg);
    }
         50% {
        transform: scaleX(-1) rotate(-0deg)
         }
                  50% {
                      transform: scaleX(-1) rotate(0deg)
                  }
                  100% {
                transform: scaleX(-1) rotate(5deg)
                  }

    

}