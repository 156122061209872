code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: black;
  background-image: url(/static/media/pramod-tiwari-3UgbE1b43j8-unsplash.7bd4b931.jpg);
  background-attachment: fixed;
  background-size: 120%;
  margin: 0;
  /* font-family: 'Lilita One', cursive; */
  font-family: 'Fredoka One', cursive;
  color: white;
}
.startBtn{
  /* height: 8em; */
  font-size:100%
  
}

.gameText{
    color:white;
    margin:.5em;
    text-shadow: 4px 4px 2px black; 
}
a:link {
    text-decoration: none;
}

.hidden {
  opacity: 0
}

.card {
    box-shadow: 10px 10px 5px black;
    height: 90%
}
.App {
  width: 100vw;
  height: 100vh;
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@-webkit-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
}
.btn {
    text-shadow:3px 3px 2px black; 
    box-shadow: 5px 5px 5px black;
}
.dice {
    height: 150px;
    width: 150px;
    position: relative;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    transition: -webkit-transform .96s;
    transition: transform .96s;
    transition: transform .96s, -webkit-transform .96s;
}

.panel {
    border-radius: 10px;
    width: 150px;
    height: 150px;
    -webkit-perspective: 400px;
            perspective: 400px;
    position: relative;
    left: 50%;
    top: 30%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.dot {
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin: 10px;
    background-color: Black;
}

.side {
    position: absolute;
    background-color: rgb(243, 220, 220);
    width: 150px;
    height: 150px;
    border-radius: 16px;
    line-height: 150px;
    border-color: rgb(72, 71, 71);
    border-width: 5px;
    border-style: ridge;

}

.one {
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transform: rotateX(-90deg) translateZ(74px);
            transform: rotateX(-90deg) translateZ(74px);
    z-index: 1;
}

.two {
    display: flex;
    justify-content: space-between;
    -webkit-transform: rotateY(180deg) translateZ(74px);
            transform: rotateY(180deg) translateZ(74px);
    z-index: 2;
}

.two .dot:nth-of-type(2) {
    align-self: flex-end;
}

.three {
    display: flex;
    justify-content: space-between;
    -webkit-transform: rotateY(-90deg) translateZ(74px);
            transform: rotateY(-90deg) translateZ(74px);
    z-index: 3;
}

.three .dot:nth-of-type(2) {
    align-self: center;
}

.three .dot:nth-of-type(3) {
    align-self: flex-end;
}

.four {
    z-index: 4;
    -webkit-transform: rotateY(0deg) translateZ(74px);
            transform: rotateY(0deg) translateZ(74px);
}

.four,
.six {
    display: flex;
    justify-content: space-between;
}

.four .kolona,
.six .kolona {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.five {
    display: flex;
    justify-content: space-between;
    -webkit-transform: rotateY(90deg) translateZ(74px);
            transform: rotateY(90deg) translateZ(74px);
    z-index: 5;
}

.five .kolona {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.five .kolona:nth-of-type(2) {
    justify-content: center;
}

.six {
    -webkit-transform: rotateX(90deg) translateZ(74px);
            transform: rotateX(90deg) translateZ(74px);
    z-index: 6;
}

.diceBtn {
    padding: .5em;
    margin: .5em;
    font-size: large;
    width: 3.3em
}

/* CUSTOMIZE THE WIGGLE EFFECT LATER */
@-webkit-keyframes wiggle {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    80% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    85% {
        -webkit-transform: rotate(5deg);
                transform: rotate(5deg);
    }

    95% {
        -webkit-transform: rotate(-5deg);
                transform: rotate(-5deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}
@keyframes wiggle {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    80% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    85% {
        -webkit-transform: rotate(5deg);
                transform: rotate(5deg);
    }

    95% {
        -webkit-transform: rotate(-5deg);
                transform: rotate(-5deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

.wiggle {
    display: inline-block;
    -webkit-animation: wiggle .5s;
            animation: wiggle .5s;
}
.factory{
    border-style: groove;
    border-color: rgb(199, 199, 192);
    border-width: .3em;
    min-height: 400px;
    box-shadow: inset -2em 2em 2em black;
    max-width: 90%;
    background-image: url("/krzysztof-kowalik-djdoRnnKLyo-unsplash.jpg");
    background-size: cover;
    background-position: 50%;
}
.rnd-btn{
    margin:.3em;
    width: 80px
}
.dev_border{
    border-style:dotted;
    border-color: white;
    border-width: 2px;
}
.new_head{
    -webkit-transform: rotateX(-90deg) translateZ(-50px);
        transform: rotateX(-90deg) translateZ(-50px);
        transition: -webkit-transform .3s;
        transition: transform .3s;
        transition: transform .3s, -webkit-transform .3s;
            /* Animate the transform properties */
        
            -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
            /* <-NB */
            height: 100px;
}
.static {
    -webkit-animation-name: static;
            animation-name: static;
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

.static_swinging {
    -webkit-animation-name: static_swinging;
            animation-name: static_swinging;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;   
}
.static_swinging_right {
    -webkit-animation-name: static_swinging_right;
            animation-name: static_swinging_right;
    -webkit-animation-duration: 3.8s;
            animation-duration: 3.8s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}
.drive {
    -webkit-transform: scale(.7);
            transform: scale(.7);
    -webkit-animation-name: drive;
            animation-name: drive;
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
    ;
}
@-webkit-keyframes drive {
    0% {-webkit-transform: scale(.01);transform: scale(.01);
        left: 300px;
        top: 200px
        
    }

    25% {
        -webkit-transform: scale(.1);
                transform: scale(.1);
            left: 100px;
            top: 200px
    }

    50% {
        -webkit-transform: scale(.5);
                transform: scale(.5);
            left: 25px;
            top: 200px
    }

   

    100% {
        -webkit-transform: scale(.7);
                transform: scale(.7);
        top:0;
        
    }
}
@keyframes drive {
    0% {-webkit-transform: scale(.01);transform: scale(.01);
        left: 300px;
        top: 200px
        
    }

    25% {
        -webkit-transform: scale(.1);
                transform: scale(.1);
            left: 100px;
            top: 200px
    }

    50% {
        -webkit-transform: scale(.5);
                transform: scale(.5);
            left: 25px;
            top: 200px
    }

   

    100% {
        -webkit-transform: scale(.7);
                transform: scale(.7);
        top:0;
        
    }
}

@-webkit-keyframes static {
    0% {
        left: 20px ;
    }

    25% {
        left: 25px;
    }

    50% {
        left: 25px;
    }

    75% {
        left: 30px;
    }

    100% {
        left: 20px;
    }
}

@keyframes static {
    0% {
        left: 20px ;
    }

    25% {
        left: 25px;
    }

    50% {
        left: 25px;
    }

    75% {
        left: 30px;
    }

    100% {
        left: 20px;
    }
}

@-webkit-keyframes static_swinging {
    0% {
        -webkit-transform: rotate(5deg);
                transform: rotate(5deg);
    }

    25% {
        -webkit-transform: rotate(6deg);
                transform: rotate(6deg);
    }

    50% {
        -webkit-transform: rotate(3deg);
                transform: rotate(3deg);
    }

    75% {
        -webkit-transform: rotate(2deg);
                transform: rotate(2deg);
    }

    100% {
        -webkit-transform: rotate(5deg);
                transform: rotate(5deg);
    }
}

@keyframes static_swinging {
    0% {
        -webkit-transform: rotate(5deg);
                transform: rotate(5deg);
    }

    25% {
        -webkit-transform: rotate(6deg);
                transform: rotate(6deg);
    }

    50% {
        -webkit-transform: rotate(3deg);
                transform: rotate(3deg);
    }

    75% {
        -webkit-transform: rotate(2deg);
                transform: rotate(2deg);
    }

    100% {
        -webkit-transform: rotate(5deg);
                transform: rotate(5deg);
    }
}
@-webkit-keyframes static_swinging_right {
    0% {
        -webkit-transform: scaleX(-1) rotate(5deg);
                transform: scaleX(-1) rotate(5deg);
    }
         50% {
        -webkit-transform: scaleX(-1) rotate(-0deg);
                transform: scaleX(-1) rotate(-0deg)
         }
                  50% {
                      -webkit-transform: scaleX(-1) rotate(0deg);
                              transform: scaleX(-1) rotate(0deg)
                  }
                  100% {
                -webkit-transform: scaleX(-1) rotate(5deg);
                        transform: scaleX(-1) rotate(5deg)
                  }

    

}
@keyframes static_swinging_right {
    0% {
        -webkit-transform: scaleX(-1) rotate(5deg);
                transform: scaleX(-1) rotate(5deg);
    }
         50% {
        -webkit-transform: scaleX(-1) rotate(-0deg);
                transform: scaleX(-1) rotate(-0deg)
         }
                  50% {
                      -webkit-transform: scaleX(-1) rotate(0deg);
                              transform: scaleX(-1) rotate(0deg)
                  }
                  100% {
                -webkit-transform: scaleX(-1) rotate(5deg);
                        transform: scaleX(-1) rotate(5deg)
                  }

    

}
img{
    max-width: 100%;
    max-height: 80%;
}
.box {
    margin: .5em;
    width: 6em;
    height:6em;
    -webkit-perspective: 1000px;
            perspective: 1000px;
}
.btn-primary {text-shadow: 2px 2px black}


/* This container is needed to position the front and back side */
.flip-card-inner {
    
    width: 100%;
    height: 100%;
    text-align: center;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flipped {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}


/* Position the front and back side */
.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    /* Safari */
    backface-visibility: hidden;
}


/* Style the front side (fallback if image is missing) */
.flip-card-front {
    background-color:rgb(177, 29, 29);
    color: black;
    border-style: solid;
    border-color: black;
}

/* Style the back side */
.flip-card-back {
    width: 100%;
    background-color: rgb(222, 244, 212);
    color: rgb(0, 0, 0);
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    box-shadow: "0 0 50px #76ff03";
            width: 40;
            height: 40;
                  -webkit-animation: "bounce 1s infinite";
                          animation: "bounce 1s infinite"
}
.game-window {
	position: relative;
	height: auto;
	margin-top: auto;
	margin-bottom: auto;
	border: yellow;
}

.balloon {
	position: absolute;
	width: 45px;
	height: 65px;
	/* background-color: #ff4081; */
	border-radius: 50%;
}
.btn {
	text-shadow: 3px 3px 2px black;
	box-shadow: 5px 5px 5px black;
}
.game-over {
	position: absolute;
	height: auto;
}

